<template>
	<!-- 创建弹框 -->
	<el-dialog
		width="70%"
		center
		title="选择员工"
		:visible.sync="staffDialog"
		:before-close="handleClose"
		:close-on-click-modal="false"
		:close-on-press-escape="false"
		append-to-body
		@open="openDialog"
	>
		<div class="search_box">
			<el-input placeholder="请输入姓名或手机号查询" style="width: 40%" v-model.trim="keyWork" @keyup.enter.native="query">
				<i slot="suffix" class="el-input__icon el-icon-search search_icon" @click="query"></i>
			</el-input>
		</div>
		<el-table
			ref="multipleTable"
			:data="staffList"
			tooltip-effect="dark"
			:row-key="getRowKey"
			v-loading="loading"
			class="tableBackground"
			style="width: 100%"
			@select-all="selectAll"
			@select="handleSelectChange"
		>
			<el-table-column type="selection" width="60" align="center" :selectable="selectable"></el-table-column>
			<el-table-column prop="staffName" label="姓名" width align="center"></el-table-column>
			<el-table-column prop="phoneNumber" label="手机号" width align="center"></el-table-column>
			<el-table-column prop="jobName" label="岗位" width align="center"></el-table-column>
			<el-table-column prop="jobNumber" label="工号" width align="center"></el-table-column>
			<el-table-column prop="mailbox" label="工作邮箱" width="200" align="center"></el-table-column>
		</el-table>
		<div style="display: flex; justify-content: flex-end">
			<el-pagination
				style="margin: 20px 0"
				@current-change="handleCurrentChange"
				:current-page="pageNo"
				:page-size="10"
				background
				layout="total,prev, pager, next"
				:total="totalNum"
			></el-pagination>
		</div>

		<span slot="footer" class="dialog-footer">
			<el-button @click="cancle">取 消</el-button>
			<el-button type="primary" @click="emitList">确定</el-button>
		</span>
		<!-- </div> -->
	</el-dialog>
</template>
<script>
export default {
	props: {
		staffDialog: {
			type: Boolean
		},
		orgId: {
			type: String
		},
		userList: Array,
		setChecks: Array,
		index: {
			type: String
		},
		isOnly: { type: Boolean, default: false },
		queryType: String
	},
	data() {
		return {
			loading: false,
			staffList: [],
			keyWork: '',
			totalNum: 0,
			pageNo: 1,
			title: '组织名称',
			disabled: true,
			selectStaffList: [],
			radio: '',
			selected: {}
		};
	},
	mounted() {},
	methods: {
		openDialog() {
			this.$nextTick(() => {
				if (this.userList && this.userList.length === 0) {
					this.$refs.multipleTable.clearSelection();
					this.selectStaffList = [];
				}
			});
			this.pageNo = 1;
			this.keyWork = '';
			this.query();
		},
		query() {
			this.getStaffList();
		},
		getRowKey(row) {
			return row.staffId;
		},
		// 获取员工列表
		getStaffList() {
			this.loading = true;
			const params = {
				pageNo: this.pageNo,
				pageSize: 10,
				orgId: this.orgId,
				keyWork: this.keyWork
			};

			if (this.queryType === '2') {
				params.queryType = '2';
			}
			this.$axios
				.post('/v1/org/department/staff/list', {
					request: params,
					sign: ''
				})
				.then((res) => {
					this.totalNum = Number(res.totalNum);
					this.staffList = res.list;
					this.loading = false;
					this.$nextTick(() => {
						if (this.userList) {
							// 选中状态
							this.staffList.forEach((row) => {
								for (const j in this.userList) {
									if (this.userList[j].staffId) {
										// 审批模块
										if (row.staffId == this.userList[j].staffId) {
											this.$refs.multipleTable.toggleRowSelection(row, true);
										}
									}
									//   else {
									//     if (row.staffId == this.userList[j]) {
									//       //计划模块
									//       this.$refs.multipleTable.toggleRowSelection(row, true)
									//     }
									//   }
								}
							});
						} else {
							this.$refs.multipleTable.clearSelection();
						}
					});
				});
		},
		emitList() {
			if (this.userList && this.userList.length > 0) {
				// 获取已选中同部门的员工
				const orgStaff = this.userList.filter((item) => item.orgId == this.orgId && this.orgId);

				if (orgStaff.length > 0) {
					//   let staffs = orgStaff.map(v => v.staffId)
					//   let checkStaffs = [...this.selectStaffList]
					//   for (let i = 0; i < checkStaffs.length; i++) {
					//     if (
					//       checkStaffs[i].orgId == this.orgId &&
					//       !staffs.includes(checkStaffs[i].staffId)
					//     ) {
					//       this.selectStaffList.splice(i, 1)
					//     }
					//   }
				}
			}
			// 计划
			//   if (
			//     this.orgId == undefined &&
			//     this.selectStaffList.length > 1 &&
			//     !this.index
			//   ) {
			//     // this.$message.error('只能选择一位确认人')
			//     return
			//   }
			this.$emit('update:staffDialog', false);
			this.$emit('getStaff', this.selectStaffList);
		},
		// 去重
		reduce(arr) {
			const obj = {};

			// eslint-disable-next-line no-param-reassign
			arr = arr.reduce((item, next) => {
				// eslint-disable-next-line no-unused-expressions
				obj[next.staffId] ? '' : (obj[next.staffId] = true && item.push(next));
				return item;
			}, []);
			return arr;
		},
		// 全选
		selectAll(selection) {
			if (this.isOnly) {
				this.$refs.multipleTable.clearSelection();
				return;
			}
			let onCheckList = [];

			onCheckList = selection;
			for (const check of onCheckList) {
				if (!check.orgId) {
					check.orgId = this.orgId;
					check.id = check.staffId;
					check.name = check.staffName;
				}
			}
			this.selectStaffList = onCheckList;
		},

		// 表格选择
		handleSelectChange(selection, row) {
			if (this.isOnly) {
				this.$refs.multipleTable.clearSelection();
				if (selection.length === 0) {
					return;
				}
				// 这这里将这行的状态又变为了勾选
				this.$refs.multipleTable.toggleRowSelection(row, true);
				this.selectStaffList = [row];
			} else {
				let onCheckList = [];
				// 反选

				if (selection.length && selection.indexOf(row) == -1) {
					const reduceList = this.reduce(selection);

					onCheckList = reduceList.filter((item) => item.staffId != row.staffId);
				} else {
					onCheckList = selection;
				}

				for (const check of onCheckList) {
					if (!check.orgId) {
						check.orgId = this.orgId;
						check.id = check.staffId;
						check.name = check.staffName;
					}
				}
				this.selectStaffList = onCheckList;
			}
		},
		handleCurrentChange(val) {
			this.pageNo = val;
			this.getStaffList();
		},
		cancle() {
			this.$emit('update:staffDialog', false);
		},
		handleClose() {
			this.$emit('update:staffDialog', false);
		},
		selectable({ staffId }) {
			return !this.setChecks.includes(staffId);
		}
	}
};
</script>
<style lang="scss">
.search_box {
	margin-bottom: 20px;
}
.search_icon {
	padding-right: 15px;
}
</style>
