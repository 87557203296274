<template>
	<div :class="{ inline: !showStaff }">
		<el-popover placement="bottom" width="400" :offset="100" trigger="click">
			<el-tree
				:data="treeData"
				id="deptTree"
				node-key="id"
				:show-checkbox="showCheckbox"
				ref="tree"
				:check-strictly="checkStrictly"
				@check="checkChange"
				:render-content="renderContent"
			>
			</el-tree>
			<el-button :disabled="disable" slot="reference" :size="sizes" icon="el-icon-plus" type="text">{{ text }}</el-button>
		</el-popover>
		<staffList
			@getStaff="getStaff"
			:userList="userList"
			:setChecks="setChecks"
			:staffDialog.sync="staffDialog"
			:orgId="orgId"
			:queryType="queryType"
		></staffList>
	</div>
</template>
<script>
import staffList from '@/components/departs/staffList.vue';
import { queryDepartment } from '@/api/workbench';
export default {
	props: {
		showStaff: Boolean,
		userList: {
			type: Array,
			default: () => []
		},
		queryType: String,
		btnText: String,
		checkStrictly: Boolean,
		setChecks: Array,
		showCheckbox: {
			default: true,
			type: Boolean
		},
		disable: {
			default: false,
			type: Boolean
		},
		sizes: {
			default: 'mini',
			type: String
		}
	},
	components: {
		staffList
	},
	watch: {
		setChecks() {
			// eslint-disable-next-line vue/no-mutating-props
			// this.setChecks = val;
			this.$refs.tree.setCheckedKeys(this.setChecks);
		}
	},
	data() {
		return {
			treeData: [],
			orgId: '',
			orgIds: [],
			orgTags: [],
			staffDialog: false,
			tableData: [],
			userName: '',
			// 分页参数
			pagenation: {
				pageNo: 1,
				pageSize: 20,
				totalNum: 0
			},
			text: '添加部门或员工'
		};
	},
	mounted() {
		if (this.btnText) {
			this.text = this.btnText;
		}
		this.queryDepartment();
	},
	methods: {
		queryDepartment() {
			queryDepartment({
				request: {}
			})
				.then((res) => {
					this.treeData = res;
					this.$store.commit('SET_DEPT', res);
				})
				.catch(() => {});
		},
		// getCheckNodes() {
		//   let nodes = this.$refs.tree.getNode(1)
		// },
		renderContent(h, { data }) {
			if (this.showStaff) {
				if (data.directNumber > 0) {
					return (
						<span class="custom-tree-node">
							<span>{data.orgName}</span>
							<span>
								<el-button size="mini" icon="el-icon-user" type="text" on-click={() => this.getDepUser(data.id)}></el-button>
							</span>
						</span>
					);
				}
				return (
					<span class="custom-tree-node">
						<span>{data.orgName}</span>
					</span>
				);
			}
			return (
				<span class="custom-tree-node">
					<span>{data.orgName}</span>
				</span>
			);
		},
		checkChange(data, checked) {
			if (checked && checked.checkedKeys.length > 0) {
				this.orgTags = checked.checkedNodes;
			} else {
				// 移除标签
				this.orgTags = [];
				//   this.orgTags.forEach((item,index,arr) => {
				//   console.log(data);
				//     if (item.id == data.id) {
				//       this.orgTags.splice(index, 1);
				//     }
				//     // if(this.setChecks.indexOf(data.orgPid) >-1){
				//     //     this.orgTags.splice(this.setChecks.indexOf(data.orgPid), 1);
				//     //   }
				//   })
			}
			this.$emit('updateTag', this.orgTags, checked);
		},
		getDepUser(id) {
			this.staffDialog = true;
			this.orgId = id;
		},
		getStaff(staffs) {
			this.$emit('updateStaffTag', staffs);
		},
		handleSizeChange(val) {
			this.pagenation.pageSize = val;
			this.params.pageSize = val;
		},
		handleCurrentChange(val) {
			this.pagenation.pageNo = val;
			this.params.pageNo = val;
		}
	}
};
</script>
<style lang="scss">
.custom-tree-node {
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 14px;
	padding-right: 8px;
}
.inline {
	display: inline-block;
	margin-left: 10px;
}
#deptTree > .el-tree-node:first-child > .el-tree-node__content {
	// .is-leaf + .el-checkbox .el-checkbox__inner {
	//   display: block;
	// }
	.el-checkbox .el-checkbox__inner {
		color: red;
		// display: none;
	}
}
</style>
